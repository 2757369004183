'use client'

import { ReactNode, useState } from "react"
import Image from 'next/image'
import '../../styles/global.css'

import { Inter } from 'next/font/google'
import { Footer } from "@/layout/Footer"
import Link from "next/link"
import { DessertIcon } from "@/utils/DessertIcon"
const inter = Inter({
  subsets: ['latin'],
})

export default function RootLayout({
  children,
}: Readonly<{
  children: ReactNode
}>) {
  const [hamburger, setHamburger] = useState(false)
  
  function toggleHamburger() {
    setHamburger(!hamburger)
  }

  return (
    <>
      <header 
        id='desktop-header'
        className="hidden md:flex flex-row items-center px-8"
      >
        <div className="flex flex-row my-2 items-center">
          <Image 
            src={`/assets/images/dessertdata/cake1.png`} 
            alt="cake logo" 
            width={30}
            height={30}
            className=""
          />
          <p className="text-md font-bold mx-2">Dessert Data</p>
          <p className="text-sm bg-theme-brand-50 text-theme-brand-600 px-2 rounded-lg">Beta</p>
        </div>
        <nav className="flex-grow mx-6 text-sm">
          <Link className="inline-block mx-2" href='/pricing'>Pricing</Link>
          <Link className="inline-block mx-2" href='/blog'>Guides</Link>
          <Link className="inline-block mx-2" href='/about-us'>About Us</Link>
        </nav>
        <nav className="text-xs">
          <Link className="inline-block mx-2 text-theme-brand-600 font-bold" href='/login'>Sign In</Link>
          <Link className="inline-block mx-2 border-gray-theme-300 bg-white border-[1px] px-2 py-1 rounded-md text-theme-gray-700" href='/demo'>Book demo</Link>
          <Link className="inline-block mx-2 bg-purple-dark px-2 py-1 rounded-md text-white" href='/signup'>Sign Up</Link>
        </nav>
      </header>
      <header 
        id='mobile-header'
        className="flex md:hidden items-center justify-between px-4 mt-2"
      >
        <div className="flex flex-row my-1 items-center">
          <Image 
            src={`/assets/images/dessertdata/cake1.png`} 
            alt="cake logo" 
            width={30}
            height={30}
            className=""
          />
          <p className="text-md font-bold mx-2 text-theme-gray-600">Dessert Data</p>
          <p className="text-sm bg-theme-brand-50 text-theme-brand-600 px-2 rounded-lg">Beta</p>
        </div>
        <div>
          <Image 
            src={`/assets/images/hamburger_icon.svg`} 
            alt="Menu" 
            className="h-10 opacity-40 inline-block"
            height={30}
            width={30}
            onClick={() => toggleHamburger()}
          />
        </div>
      </header>

      <section className={hamburger ? "" : "hidden"}>
        <div className='transition absolute z-50 top-0 left-0 bg-theme-gray-25 w-full px-4 py-16 text-lg shadow-lg'>
          <a className="float-right block -mt-11 text-md font-bold" onClick={() => toggleHamburger()}>close</a>
          <h4 className="text-theme-gray-800 text-center font-semibold text-xl">Menu <DessertIcon rand={Math.random()}/></h4>
          <nav className="mx-6">
            <Link className="block m-2 text-theme-brand-600 font-bold" href='/demo'>Book demo</Link>
            <Link className="block m-2" href='/pricing'>Pricing</Link>
            <Link className="block m-2" href='/blog'>Guides</Link>
            <Link className="block m-2" href='/about-us'>About Us</Link>
            <Link className="block m-2 text-theme-brand-600 font-bold" href='/login'>Sign In</Link>
            <Link className="block m-2 text-theme-brand-600 font-bold" href='/signup'>Sign Up</Link>
          </nav>
        </div>
      </section>
      {children}
      <Footer />
    </>
  );
}
