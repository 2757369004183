import { AppConfig } from "@/utils/AppConfig"
import Link from "next/link"

type IFooterProps = {
}

const Footer = (props: IFooterProps) => {
  return (
    <div className="border-t border-gray-300 text-center py-8 text-sm">
      <nav>
        <ul className="flex flex-wrap justify-center">
          <li className="mr-6">
            <Link href="/privacy-policy/" className="text-gray-700 border-none hover:text-gray-900">
                Privacy Policy
            </Link>
          </li>
          <li className="mr-6">
            <Link href="/terms-and-conditions/" className="text-gray-700 border-none hover:text-gray-900">
                Terms And Conditions
            </Link>
          </li>
          <li className="mr-6">
            <Link href="/about-us/" className="text-gray-700 border-none hover:text-gray-900">
                About Us
            </Link>
          </li>
        </ul>
      </nav>
      <p>
        <a href={ AppConfig.email }>{ AppConfig.email }</a>
      </p>
      © Copyright {new Date().getFullYear()} DessertData, LLC. 
    </div>
  )
}

const FooterFlat = (props: IFooterProps) => {
  return (
    <div className="py-8 mx-8 text-sm">
      <nav className="flex flex-row justify-between md:max-w-xl m-auto">
          <Link href="/privacy-policy/" className="text-gray-700 border-none hover:text-gray-900">
              Privacy Policy
          </Link>
          <Link href="/terms-and-conditions/" className="text-gray-700 border-none hover:text-gray-900">
              Terms And Conditions
          </Link>
          <Link href="/about-us/" className="text-gray-700 border-none hover:text-gray-900">
              About Us
          </Link>
      </nav>
      <p className="w-full text-center mt-2">© Copyright {new Date().getFullYear()} DessertData, LLC. </p>
    </div>
  )
}

export { Footer, FooterFlat }
